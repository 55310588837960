<template>
  <div>
    <fieldsTds
      field="diameterApps"
      :fieldAttributes="result.fields['diameterApps']"
      :templateContent="result"
      @input="checkvalue('diameterApps', ...arguments)"
      :value="result.valueLine.diameterApps"
    ></fieldsTds>

    <fieldsTds
      v-if="!answerStyle"
      field="commandCode"
      :fieldAttributes="result.fields['commandCode']"
      :templateContent="result"
      @input="checkvalue('commandCode', ...arguments)"
      :value="result.valueLine.commandCode"
    ></fieldsTds>

    <fieldsTds
      v-else
      field="commandCodeAnswers"
      :fieldAttributes="result.fields['commandCodeAnswers']"
      :templateContent="result"
      @input="checkvalue('commandCodeAnswers', ...arguments)"
      :value="result.valueLine.commandCodeAnswers"
    ></fieldsTds>

    <v-row no-gutters>
      <defaultFieldTypes
        :field="'request'"
        :fieldAttributes="result.fields['request']"
        @input="checkvalue('request', ...arguments)"
        :templateContent="result"
        :value="result.valueLine.request"
      ></defaultFieldTypes>
      <defaultFieldTypes
        :field="'proxiable'"
        :fieldAttributes="result.fields['proxiable']"
        @input="checkvalue('proxiable', ...arguments)"
        :templateContent="result"
        :value="result.valueLine.proxiable"
      ></defaultFieldTypes>
      <defaultFieldTypes
        :field="'error'"
        :fieldAttributes="result.fields['error']"
        @input="checkvalue('error', ...arguments)"
        :templateContent="result"
        :value="result.valueLine.error"
      ></defaultFieldTypes>
      <defaultFieldTypes
        :field="'retransmitted'"
        :fieldAttributes="result.fields['retransmitted']"
        @input="checkvalue('retransmitted', ...arguments)"
        :templateContent="result"
        :value="result.valueLine.retransmitted"
      ></defaultFieldTypes>
    </v-row>
  </div>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  components: { fieldsTds, defaultFieldTypes },
  props: { result: {} },
  methods: {
    checkvalue(field, value, text) {
      //console.log("field single row",field, value, text);
      this.$emit("field-value-changed", field, value, text);
    },
  },
  computed: {
    answerStyle() {
      let answerStyle = true;
      if (this.result.valueLine.request == 1) {
        answerStyle = false;
      }
      return answerStyle;
    },
  },
};
</script>