<template>
    <div class="mt-2">
        <v-row no-gutters class="mt-2"
               v-if="isset(result.fields['c_type']['value']) &&  result.fields['c_type']['value'] !== 'RADIUS'"
        >
            <v-col class="mr-2">
                <fieldsTds
                        field="c_name"
                        :fieldAttributes="result.fields['c_name']"
                        :templateContent="result"
                        @input="checkvalue('c_name', ...arguments)"
                        :value="result.valueLine.c_name"
                ></fieldsTds>
                <fieldsTds
                        field="c_description"
                        :fieldAttributes="result.fields['c_description']"
                        :templateContent="result"
                        @input="checkvalue('c_description', ...arguments)"
                        :value="result.valueLine.c_description"
                ></fieldsTds>
            </v-col>

            <v-col
                    v-if="isset(result.fields['c_type']['value']) &&  result.fields['c_type']['value'] ==='DIAMETER'"
            >
                <div class="diameterInfoContainer">
                    <diameterHeaderTemplate
                            :result="result"
                            v-on="$listeners"
                    ></diameterHeaderTemplate>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import fieldsTds from "@/commonComponents/fieldsTds.vue";
    import diameterHeaderTemplate from "@/components/legacy/diameterHeaderTemplate.vue";

    export default {
        components: {fieldsTds, diameterHeaderTemplate},
        props: {result: {}},
        methods: {
            checkvalue(field, value, text) {
//console.log("field single row",field, value, text);
                this.$emit("field-value-changed", field, value, text);
            },
        },
    };
</script>
